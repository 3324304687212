html {
  height: 100%;
  font-size: 62.5%;
}
body {
  background-color: #000;
  height: 100%;
  position: relative;
  overflow: hidden;
  font-size: 1.6rem;
  color: #fff;
  font-family: $roboto;
  background: url(/images/page-bg.jpg) no-repeat;
  background-size: cover;
  &.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    height: 100% !important;
  }
}
img {
  max-width: 100%;
}
#root {
  position: relative;
  min-height: 100%;
}
a {
  color: $yellow;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}
.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 1rem;
  padding-right: 1rem;
}
.container {
  padding-left: 1rem;
  padding-right: 1rem;
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
}
.container {
  max-width: 100% !important;
}
.login-area {
  padding-top: 2rem;
  a {
    display: inline-block;
    margin-left: .3rem;
    margin-right: .3rem;
    float: left;
  }
  small {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    width: 90%;
    text-overflow: ellipsis;
  }
}
.swal2-shown,
.ReactModal__Body--open {
	#root {
		//filter: blur(5px);
	}
}
.swal2-container.swal2-shown {
  background-color: rgba(0,0,0,.8) !important;
  .swal2-popup {
    display: flex;
  }
}
.swal2-container {
  .swal2-popup {
		width: 60% !important;
		height: auto !important;
		min-height: 60%;
		background: url(/images/popup-bg.png) no-repeat center bottom;
		background-size: 100% 100%;
    border: 0;
    padding-top: 3rem;
		padding-bottom: 3rem;
    .swal2-close,
    .close {
			position: absolute;
			width: 4rem;
			height: 4rem;
			background: url(/images/close.png) no-repeat center;
			background-size: 100%;
			top: -3rem;
      right: -4.5rem;
      opacity: 1;
      font-size: 4rem;
      outline: none !important;
      color: #fff !important;
      font-family: serif;
      font-weight: 300;
      text-indent: -9999px;
    }
    #swal2-content,
    .modal-description  {
      font-size: 2.5rem;
			margin-bottom: 2rem;
      margin-top: 3rem;
      color: #fff;
      p {
        font-size: 2.5rem;
        color: #fff;
      }
    }
    #swal2-title,
    h2 {
			position: absolute;
			font-size: 3rem !important;
			line-height: 7rem;
			white-space: nowrap;
			text-transform: uppercase;
			font-size: 3.5rem;
			color: $yellow;
			padding: 0 5rem;
			min-width: 20rem;
			text-align: center;
			display: inline-block !important;
			top: 0;
			font-weight: bold;
			left: 50%;
			transform: translateX(-50%);
			overflow: hidden;
			z-index: 1;
    }
  }
}
.ReactModal__Overlay {
  display: flex!important;
  position: fixed!important;
  top: 0!important;
  right: 0!important;
  bottom: 0;
  left: 0!important;
  flex-direction: row!important;
  align-items: center!important;
  justify-content: center!important;
  padding: 10px!important;background: rgba(0, 0, 0, .6) !important;
  z-index: 1060!important;
  overflow-x: hidden!important;
  -webkit-overflow-scrolling: touch !important;
}
.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  font-size: 1rem;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0!important;
  width: 60% !important;
  min-height: 60% !important;
  background: url(/images/popup-bg.png) no-repeat center bottom;
	background-size: 100% 100%;
  border: 0 !important;
  padding-top: 5rem !important;
  padding-bottom: 3rem !important;
	text-align: center;
  .close {
    position: absolute;
    width: 4rem;
    height: 4rem;
    background: url(/images/close.png) no-repeat center;
    background-size: 100%;
    text-indent: -9999px;
    top: -3rem;
    right: -4.5rem;
    opacity: 1;
    text-indent: -9999px;
  }
  h2 {
    position: absolute;
    font-size: 3rem !important;
    line-height: 7rem;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 3.5rem;
    color: $yellow;
    padding: 0 5rem;
    min-width: 20rem;
    text-align: center;
    display: inline-block !important;
    top: 0;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    z-index: 1;
  }
	p,
	li {
    font-size: 2.5rem;
    color: #fff;
  }
}
#main-body {
  height: 100vh;
}
.main-home {
  padding-top: 5rem;
}
#header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  z-index: 9;
}
.logo {
  display: inline-block;
  width: 26%;
  margin-top: 1rem;
  margin-left: 1rem;
}
.login-url {
  display: inline-block;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-family: $font-heading;
  margin-top: 1rem;
  margin-right: 1rem;
  span {
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    max-width: 15rem;
    text-overflow: ellipsis;
    display: inline-block;
    float: left;
    margin-right: 1rem;
  }
}
.title-site {
  width: 55%;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3rem;
}
.sub-title {
  text-align: center;
  text-transform: uppercase;
  color: $yellow;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}
.wrap-zone {
  width: 76%;
  margin-left: auto;
  margin-right: auto;
  .col-4 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.zone {
  position: relative;
  display: block;
  background: $yellow;
  &.active {
    .zone-bg {
      opacity: 0;
    }
    .selection {
      span {
        border-color: #171717;
        color: #171717;
        &:before {
          border-color: #171717 transparent transparent transparent;
        }
      }
    }
    h3 {
      color: #171717;
    }
  }
  .zone-bg {
    width: 100%;
    display: block;
    transition: opacity .25s ease;
  }
}
.zone-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 4rem 3rem;
  .zone-image {
    display: block;
    width: 76%;
    margin-left: auto;
    margin-right: auto;
  }
  h3 {
    color: #fff;
    font-family: $font-heading;
    font-size: 3.5rem;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    transition: color .25s ease;
  }
}
.selection {
  position: relative;
  &.big {
    span {
      font-size: 3rem;
      padding: .8rem 1rem;
      padding-right: 2rem;
      &:before {
        border-width: 2rem 1.5rem 0 1.5rem;
        right: 2rem;
      }
    }
  }
  span {
    pointer-events: none;
    display: block;
    width: 100%;
    font-size: 1.8rem;
    text-transform: uppercase;
    color: $yellow;
    text-align: center;
    border: 1px solid $yellow;
    padding: .3rem 1rem;
    transition: color .25s ease, border-color .25s ease;
    pointer-events: none;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1.5rem 1rem 0 1rem;
      border-color: $yellow transparent transparent transparent;
      top: 50%;
      transform: translateY(-50%);
      right: 1rem;
    }
  }
  select {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;
    top: 0;
    left: 0;
  }
}
.home-btns {
  position: absolute;
  bottom: 2rem;
  left: 0;
  width: 100%;
  .col-4 {
    padding-left: 3rem;
    padding-right: 3rem;
    flex: 0 0 30%;
    .btn {
      width: 100%;
    }
  }
  .btn-back {
    position: absolute;
    bottom: 0;
    left: 1rem;
  }
}
.zone-overview {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  .col-left,
  .col-right {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .selection {
    margin-bottom: 2rem;
  }
  .zone-image-big {
    display: block;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  .col-left {
    &:before {
      content: "";
      position: absolute;
      width: 1px;
      height: 70%;
      top: 25%;
      right: -3rem;
      background: #fff;
    }
  }
  .col-right {
    padding-left: 12rem;
  }
}
.team {
  position: relative;
  margin-bottom: 3rem;
  img {
    display: block;
    background: #171717;
    width: 100%;
  }
  span.team-name {
    font-size: 1.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: .3rem 1rem;
    background: $yellow;
    width: 100%;
    color: #171717;
  }
  span.mini-title {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    color: #fff;
    text-align: center;
    font-size: 2rem;
  }
}
.main-detail {
  height: 100vh;
  >.container {
    .row.no-gutters {
      height: 100vh;
    }
  }
}
.col-sidebar,
.col-table {
  flex: 0 0 28%;
  max-width: 28%;
}
.col-items {
  flex: 0 0 44%;
  max-width: 44%;
}
.col-sidebar {
  padding-top: 6rem;
  text-align: center;
  background: rgba(31, 31, 31, .67);
  .zone-logo {
    display: block;
    width: 68%;
    margin-left: auto;
    margin-right: auto;
  }
}
.zone-logo {
  display: inline-block;
  position: relative;
  margin-bottom: 1rem;
  img {
    display: block;
    width: 100%;
  }
  .zone-name {
    position: absolute;
    color: #171717;
    text-align: center;
    width: 48%;
    left: 50%;
    top: 64%;
    font-size: 1.6rem;
    text-transform: uppercase;
    font-family: $font-heading;
    transform: translateX(-50%);
    &.big {
      font-size: 2.5rem;
    }
  }
}
.rank-number {
  border: 1px solid $yellow;
  padding: .2rem 3rem;
  padding-bottom: .4rem;
  font-size: 5rem;
  display: inline-block;
  line-height: 100%;
  color: $yellow;
  margin-bottom: 2rem;
  line-height: 120%;
}
.account-name,
.current-point {
  padding: .5rem 1rem;
  color: #171717;
  font-size: 3rem;
  background: $yellow;
  margin-bottom: 1rem;
  a {
    color: #171717;
    display: block;
    text-align: center;
  }
}
.mini-title {
  font-size: 2.3rem;
  text-transform: uppercase;
}
.current-point {
  font-size: 6rem;
  line-height: 100%;
  padding-top: 0;
}
.col-items {
  text-align: center;
  .title-site-small {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    line-height: 100%;
  }
}
.title-season {
  color: $yellow;
  font-size: 6rem;
  text-transform: uppercase;
  line-height: 100%;
  font-family: $font-heading;
}
.season-date {
  line-height: 100%;
  font-size: 3.5rem;
}
.slide-items {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(/images/light.png) no-repeat;
    top: 0;
    left: 0;
    background-size: 100%;
  }
  .tns-outer [data-controls] {
    position: absolute;
    text-indent: -9999px;
    width: 4rem;
    height: 12rem;
    background: url(/images/arrow-left.png) no-repeat;
    background-size: 100% 100%;
    right: 110%;
    top: 50%;
    margin-top: -6rem;
  }
  .tns-outer [data-controls="next"] {
    background: url(/images/arrow-right.png) no-repeat;
    background-size: 100% 100%;
    left: 110%;
    right: auto;
  }
}
.prize-title {
  font-size: 4rem;
  text-transform: uppercase;
  line-height: 100%;
  small {
    font-size: 2rem;
    line-height: 100%;
  }
}
.col-table {
  padding-top: 6rem;
  background: rgba(31, 31, 31, .67);
  .no-gutters & {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}
.table-block {
  .selection {
    margin-bottom: 1rem;
    span {
      color: #fff;
      font-size: 2rem;
      &:before {
        border-color: #fff transparent transparent transparent;
      }
    }
  }
}
.table {
  margin-bottom: 0;
  tr {
    td {
      border: 1px solid #9c9c9c !important;
      text-align: center;
      font-size: 2rem;
      img {
        display: inline-block;
        width: 1.5rem;
      }
    }
    &:last-child {
      td {
        border-bottom: 0 !important;
      }
    }
    &.rank-up {
      td {
        color: $yellow;
      }
    }
  }
  &.my-rank {
    margin-bottom: 1rem;
    tr {
      &:first-child {
        td {
          border-top: 0 !important;
        }
      }
      td {
        background: $yellow;
        color: #171717;
      }
    }
  }
}
.main-trophy {
  padding-top: 1rem;
}
.title-trophy {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}
.wrap-trophy {
  width: 86%;
  margin-left: auto;
  margin-right: auto;
  .selection {
    width: 15%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
}
.trophy {
  width: 44%;
  margin-left: 3%;
  margin-right: 3%;
  position: relative;
  display: inline-block;
  img {
    display: block;
    width: 100%;
  }
}
.trophy-stat {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  top: 0;
  left: 0;
}
.top-text {
  color: $yellow;
  font-family: $font-heading;
  font-size: 3rem;
  margin-top: 36%;
  line-height: 120%;
  margin-bottom: 0;
  span {
    font-size: 5rem;
  }
}
.top-week {
  font-size: 3rem;
  color: #fff;
  font-family: $font-heading;
}
.title-percent {
  font-size: 10rem;
  font-family: $font-heading;
}
.btn-screenshot,
.btn-share {
  display: inline-block;
  width: 12%;
}
.popup-screenshot {
  position: fixed;
  background: rgba(0, 0, 0, .8);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  img {
    display: inline-block;
    transform: scale(.8);
    border: 1px solid $yellow;
  }
  .close {
    position: absolute;
    width: 5rem;
    height: 5rem;
    background: url(/images/close.png) no-repeat center;
    background-size: 100%;
    text-indent: -9999px;
    top: 2rem;
    right: 2rem;
    opacity: 1;
  }
}
.ScrollbarsCustom-Wrapper {
  right: 0 !important;
}
.ScrollbarsCustom-Track {
  width: 5px !important;
}
.ScrollbarsCustom-Thumb {
  background: #fff !important;
}
.ScrollbarsCustom-Content {
  display: block !important;
}
.main-exchange {
  padding-top: 2rem;
}
.title-exchange {
  text-align: center;
  font-size: 7rem;
  font-family: $font-heading;
  margin-bottom: 2rem;
  position: relative;
  display: inline-block;
  .btn-faq {
    left: 103%;
    right: auto;
  }
}
.wrap-levels {
  width: 72%;
  margin-left: auto;
  margin-right: auto;
  .col {
    flex: 0 0 20%;
    max-width: 20%;
    .spinner-dot {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
.item-cover {
  display: block;
  position: relative;
  &.passed {
    &:before {
      display: none;
    }
    img {
      animation: heartBeat .3s infinite linear;
    }
  }
  &.owned {
    img {
      animation: none;
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .6);
  }
  img {
    width: 100%;
    border: 1px solid $yellow;
    display: block;
    background: #171717;
  }
  span {
    color: #fff;
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
    display: block;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 1.5rem 1rem 0 1rem;
      border-color: #fff transparent transparent transparent;
      position: absolute;
      top: 102%;
      left: 50%;
      transform: translateX(-.75rem);
    }
  }
}
.progress-wrapper {
  width: 81%;
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
  background: #171717;
  border: 1px solid $yellow;
  margin-top: 5rem;
  position: relative;
  margin-bottom: 2rem;
  @include max-screen(1024px) {
    width: 82%;
  }
  .progress-value {
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
  }
  span {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
  }
}
.title-stat {
  margin-bottom: 2rem;
  img {
    display: block;
    width: 100%;
  }
}
.stat {
  border: 1px solid $yellow;
  display: inline-block;
  margin-bottom: 2rem;
  text-align: center;
  .btn,
  .value {
    display: inline-block;
  }
  .btn {
    float: left;
  }
  .value {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 3rem;
    color: #fff;
    min-width: 10rem;
  }
  &.big {
    .value,
    .btn {
      font-size: 4.5rem;
    }
    .value {
      min-width: 15rem;
    }
  }
}
.w80 {
  width: 80%;
  margin: auto;
}
.main-exchange .home-btns {
  bottom: 1rem;
}
.notice-text {
  font-size: 3rem;
  text-align: center;
  margin-top: 5rem;
}
.title-season {
  position: relative;
}
@keyframes heartBeartFast {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.btn-faq {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  text-align: center;
  line-height: 5rem;
  background: $yellow;
  color: #171717;
  font-weight: bold;
  font-size: 3rem;
  position: absolute;
  right: 1rem;
  border-radius: 50%;
  top: 50%;
  margin-top: -3rem;
  animation: heartBeartFast .3s infinite linear;
}
.guide-content {
  text-align: left;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  dl, ol, ul {
    margin-left: 0;
    padding-left: 3rem;
  }
}
table {
  width: 96%;
  margin-bottom: 3rem;
  tr {
    th, td {
      border: 1px solid $yellow;
      padding: 1rem;
    }
  }
}
.modal-description {
  .btn {
    margin-bottom: 1rem;
  }
}
.ell {
  display: inline-block;
  max-width: 15rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.join-fb-group {
  margin-bottom: 1rem;
  a {
    display: block;
    img {
      width: 100%;
      display: inline-block;
    }
  }
}
.btn-valentine {
  position: absolute;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 16%;
}