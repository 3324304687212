.btn-shadow {
  display: inline-block;
  padding: 0 2rem;
  height: 4.5rem;
  font-size: 2rem;
  white-space: nowrap;
  text-transform: uppercase;
  color: #fff !important;
  line-height: 4.5rem;
  border-radius: 2.5rem;
  box-shadow: 0 .5rem 0 #af2100;
  background: $orange;
  text-align: center;
  @include screen(800px, 860px) {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.8rem;
  }
  &:hover {
    transform: scale(1.05);
  }
  &.btn-purple {
    box-shadow: 0 .5rem 0 #3601a4;
    background: #7006ff;
  }
  &.btn-yellow {
    color: #1a1527 !important;
    box-shadow: 0 .5rem 0 #cca000;
    background: #fdcd00;
  }
}
.btn,
.swal2-container .swal2-popup .swal2-styled.swal2-confirm,
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  color: #131210 !important;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 2.5rem;
  border-radius: 0;
  background: $yellow;
  padding-top: .6rem;
  padding-bottom: .6rem;
  padding-right: 3rem;
  padding-left: 3rem;
  transition: background-color .25s ease;
  @include min-screen(1200px) {
    &:hover {
      background: #fff;
    }
  }
  &.btn-big {
    font-size: 3.6rem;
  }
  &.btn-bold {
    font-family: $font-heading;
  }
  &.btn-back {
    font-family: $font-heading;
  }
  &.btn-rounded {
    border: 1px solid $yellow;
    border-radius: 3rem;
    background: transparent;
    color: #fff !important;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  &.btn-long {
    padding: .1rem 5rem;
    font-size: 2.8rem;
  }
}
.swal2-container .swal2-popup .swal2-styled.swal2-cancel {
  background: $gray;
}