@include min-screen(1200px) {
  .swal2-container .swal2-popup .swal2-styled.swal2-cancel, .swal2-container .swal2-popup .swal2-styled.swal2-confirm {
    width: 16.5rem;
    font-size: 2rem;
  }
  .ScrollbarsCustom {
    height: 400px !important;
    max-height: 400px !important;
  }
  .img-total {
    max-width: 100%;
  }
}
@include screen(736px, 850px) {
  .zone-overview {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    .zone-image-big {
      width: 72%;
    }
  }
  .title-site {
    width: 36%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -4rem;
  }
  .btn {
    font-size: 2.2rem;
    padding-top: .3rem;
    padding-bottom: .3rem;
    padding-right: 2rem;
    padding-left: 2rem;
    &.btn-big {
      font-size: 3rem;
    }
  }
  .selection {
    &.big {
      span {
        font-size: 2.5rem;
      }
    }
  }
  .title-trophy {
    width: 30%;
  }
  .logo-zone {
    width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .title-percent {
    font-size: 6rem;
  }
  .col-sidebar .zone-logo {
    width: 60%;
  }
  .col-items .title-site-small {
    width: 42%;
  }
  .rank-number {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  .account-name {
    margin-bottom: 1rem;
  }
  .current-point {
    font-size: 4rem;
  }
  .title-season {
    font-size: 4rem;
  }
  .slide-items {
    width: 50%;
  }
  .ScrollbarsCustom {
    max-height: 180px !important;
  }
  .wrap-levels {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
  }
  .stat {
    .btn {
      font-size: 2.5rem;
    }
    &.big {
      .btn,
      .value {
        font-size: 3rem;
      }
    }
  }
  .title-stat {
    background: #000;
    img {
      display: block;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@include max-screen(735px) {
  .ScrollbarsCustom {
    max-height: 200px !important;
  }
}